<template>
    <div class="needs-wrapper" id="ai-solutions">
        <div class="needs-container">
            <div data-aos="fade-up" data-aos-easing="ease-in-out">
                <div class="needs-container__title">
                    Simplify content creation<br />with AI ChatBot
                </div>
                <div class="needs-container__info">
                    Discover the power of AI assistance and revolutionize your
                    daily tasks. From crafting compelling social media posts to
                    solving complex academic problems, our AI assistant makes
                    content creation seamless and efficient. Just tell the AI
                    what you need, and watch it work its magic – no coding
                    required.
                </div>
            </div>
            <div
                class="needs-container__items"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
            >
                <div
                    v-for="needItem in needsList"
                    class="needs-container__item"
                >
                    <component
                        :is="needItem.iconComponent"
                        class="needs-container__item-icon"
                    ></component>
                    <div class="needs-container__item-title">
                        {{ needItem.title }}
                    </div>
                    <div class="needs-container__item-info">
                        {{ needItem.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Picture from '@/Icons/needs/Picture.vue'
import SelfGrowth from '@/Icons/needs/SelfGrowth.vue'
import SocialMedia from '@/Icons/needs/SocialMedia.vue'
import Entertaiment from '@/Icons/needs/Entertaiment.vue'
import Education from '@/Icons/needs/Education.vue'
import Work from '@/Icons/needs/Work.vue'
import Lifestyle from '@/Icons/needs/Lifestyle.vue'
import Fitness from '@/Icons/needs/Fitness.vue'
import Emails from '@/Icons/needs/Emails.vue'
import School from '@/Icons/needs/School.vue'
import Spirituality from '@/Icons/needs/Spirituality.vue'
import Writer from '@/Icons/needs/Writer.vue'

export default {
    components: {
        Writer,
        Spirituality,
        School,
        Emails,
        Fitness,
        Lifestyle,
        Work,
        Education,
        Entertaiment,
        SocialMedia,
        SelfGrowth,
        Picture,
    },
    data() {
        return {
            needsList: [
                {
                    iconComponent: 'Picture',
                    title: 'Perfect captions',
                    description:
                        'Upload pictures to create titles, captions, hashtags',
                },
                {
                    iconComponent: 'SocialMedia',
                    title: 'Social Media',
                    description:
                        'Effortlessly craft engaging copy for your social media',
                },
                {
                    iconComponent: 'SelfGrowth',
                    title: 'Self-Growth',
                    description: 'Personalized tips to improve your life daily',
                },
                {
                    iconComponent: 'Entertaiment',
                    title: 'Entertaiment',
                    description: 'Fun activities and ideas to enjoy right now',
                },
                {
                    iconComponent: 'Education',
                    title: 'Education',
                    description:
                        'Get answers in any subject, from chemistry to literature',
                },
                {
                    iconComponent: 'Work',
                    title: 'Work',
                    description: 'CVs, promotional texts, and career advice',
                },
                {
                    iconComponent: 'Lifestyle',
                    title: 'Daily Life',
                    description: 'Find quick answers to your daily challenges',
                },
                {
                    iconComponent: 'Fitness',
                    title: 'Fitness',
                    description: 'Get customized fitness and nutrition advice',
                },
                {
                    iconComponent: 'Emails',
                    title: 'Emails',
                    description:
                        'Create professional and personal emails effortlessly',
                },
                {
                    iconComponent: 'School',
                    title: 'School',
                    description: 'Excel in your school assignments with ease',
                },
                {
                    iconComponent: 'Spirituality',
                    title: 'Spirituality',
                    description: 'Learn about famous spiritual practices',
                },
                {
                    iconComponent: 'Writer',
                    title: 'Lyric Writer',
                    description: 'Generate amazing song lyrics in seconds',
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.needs-wrapper {
    @apply w-full bg-[#0F1011];
}

.needs-container {
    @apply bg-[#0F1011] px-[20px] py-[40px] w-[375px] mt-0 m-auto;
    background-image: url('../../../images/index-landing/375/needs-bg.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center 205px;
    @screen lg {
        @apply bg-[#0F1011] mx-auto py-[80px] w-[1280px];
        background-image: url('../../../images/index-landing/1920/needs-bg.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center top;
    }
}

.needs-container__title {
    @apply font-sans text-[32px] font-bold leading-[38px] tracking-[0px] text-left text-[#ffffff];
    @screen lg {
        @apply text-[54px] leading-[66px] w-[800px];
    }
}

.needs-container__info {
    @apply font-sans mt-[24px] text-[16px] font-normal leading-[24px] tracking-[0px] text-left text-[#ffffff];
    @screen lg {
        @apply mt-[32px] text-[18px] leading-[28px] w-[780px];
    }
}

.needs-container__items {
    @apply pt-[32px] flex flex-row flex-wrap gap-[15px];
    @screen lg {
        @apply pt-[40px];
    }
}

.needs-container__item {
    @apply pl-[15px] pr-[15px] py-[15px] rounded-[20px];
    width: calc((100% - 15px) / 2);
    background-color: rgba(37, 37, 37, 0.75);
    box-shadow: 0 0 4px 0 #00000018;
    @screen lg {
        width: calc((100% - 75px) / 6);
    }
}

.needs-container__item-title {
    @apply font-sans text-[18px] font-semibold leading-[19px] tracking-[0px] text-left text-[#ffffff] mt-[10px];
}

.needs-container__item-info {
    @apply font-sans text-[14px] font-normal leading-[16px] tracking-[0px] text-left text-[#ffffff] opacity-50 mt-[2px];
    @screen lg {
        @apply mt-[6px];
    }
}
</style>

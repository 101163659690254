<template>
    <div class="reviews-wrapper" id="reviews">
        <div class="reviews">
            <div class="reviews__bg-left"></div>
            <div class="reviews__bg-right"></div>
            <div data-aos="fade-up" data-aos-easing="ease-in-out">
                <div class="reviews__title">
                    GPT technology is trusted by millions of users worldwide
                </div>
                <div class="reviews__img-container">
                    <div class="reviews__count">56 000 +</div>
                    <ReviewsRating class="reviews__img" />
                </div>
            </div>
            <div
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
            >
                <div class="reviews__carousel">
                    <Carousel ref="carousel" :breakpoints="breakpoints">
                        <Slide v-for="review in reviews" :key="review.title">
                            <div class="reviews__slide">
                                <div class="reviews__slide-header">
                                    <div class="reviews__slide-author">
                                        {{ review.author }}
                                    </div>
                                    <component :is="review.ratingComponent" />
                                </div>
                                <div
                                    class="reviews__slide-text"
                                    v-html="review.text"
                                ></div>
                            </div>
                        </Slide>
                    </Carousel>
                    <div class="reviews__carousel-navigation">
                        <ArrowLeft
                            class="reviews__carousel-navigation-btn"
                            @click="$refs.carousel.prev()"
                        />
                        <ArrowLeft
                            class="reviews__carousel-navigation-btn reviews__carousel-navigation_right"
                            @click="$refs.carousel.next()"
                        />
                    </div>
                </div>
                <div class="reviews__button-container">
                    <button
                        class="reviews__button"
                        @click="getStartedButtonClicked"
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide} from 'vue3-carousel'
import ReviewsRating from '@/Icons/reviews/ReviewsRating.vue'
import FiveStars from '@/Icons/reviews/FiveStars.vue'
import FourStars from '@/Icons/reviews/FourStars.vue'
import ArrowLeft from '@/Icons/reviews/ArrowLeft.vue'
import {START_NOW_LINK} from '@/Components/menuItems.js'
import {trackEvent} from '@/Utils/analytics.js'

export default {
    components: {
        ArrowLeft,
        FiveStars,
        FourStars,
        ReviewsRating,
        Carousel,
        Slide,
    },
    data() {
        return {
            startNowLink: START_NOW_LINK,
            breakpoints: {
                300: {
                    wrapAround: true,
                    autoplay: 3000,
                    itemsToShow: 1.2,
                },
                1280: {
                    wrapAround: true,
                    autoplay: 3000,
                    itemsToShow: 3,
                },
                1440: {
                    wrapAround: true,
                    autoplay: 3000,
                    itemsToShow: 4,
                },
                1920: {
                    wrapAround: true,
                    autoplay: 3000,
                    itemsToShow: 5,
                },
            },
            reviews: [
                {
                    author: 'jerseygirl5',
                    text: '<strong>Wow!</strong><br/> It’s like living in the future! Ask any question and it comes up with a response in seconds and answers the question! So much better than googling and searching everywhere.',
                    ratingComponent: 'FiveStars',
                },
                {
                    author: 'mylilphony1999',
                    text: '<strong>The most important aspect of this planet</strong></br> The most incredible thing I’ve ever seen and used',
                    ratingComponent: 'FiveStars',
                },
                {
                    author: 'Ashgang2162',
                    text: '<strong>Definitely helps my writers block</strong></br> 100%',
                    ratingComponent: 'FiveStars',
                },
                {
                    author: 'CodeNameBianca',
                    text: '<strong>Loving it!</strong></br> I’m astonished at how fast it comes up with answers!!!',
                    ratingComponent: 'FiveStars',
                },
                {
                    author: 'nskdbffnrb',
                    text: '<strong>Good</strong></br> Very good man',
                    ratingComponent: 'FiveStars',
                },
                {
                    author: 'Mackarthur',
                    text: '<strong>Dope</strong></br> This assistant is pretty legit actually: I was skeptic but y’all did it',
                    ratingComponent: 'FourStars',
                },
            ],
        }
    },
    methods: {
        getStartedButtonClicked() {
            trackEvent('start_page_pressed')
            trackEvent('get_started', null, 'fb')

            if (this.user) {
                this.$inertia.visit(route('chat.index'))
            } else {
                this.$inertia.visit(this.startNowLink)
            }
        },
    },
    computed: {
        user() {
            return this.$page.props.auth.user
        },
    },
}
</script>

<style lang="scss" scoped>
.reviews-wrapper {
    @apply w-full bg-[#0F1011];
}

.reviews {
    @apply relative bg-[#0F1011] w-[375px] mt-0 m-auto;
    background-repeat: no-repeat;
    background-position: top 100px center;
    background-image: -webkit-image-set(
        url('../../../../resources/images/index-landing/375/reviews-bg.svg') 1x
    );
    background-image: image-set(
        url('../../../../resources/images/index-landing/375/reviews-bg.svg') 1x
    );
    @screen lg {
        @apply w-full max-w-[1920px];
        background-position: top 80px center;
        background-image: -webkit-image-set(
            url('../../../../resources/images/index-landing/1920/reviews-bg.svg')
                1x
        );
        background-image: image-set(
            url('../../../../resources/images/index-landing/1920/reviews-bg.svg')
                1x
        );
    }
}

.reviews__bg-left {
    @apply hidden pointer-events-none;
    @screen lg {
        @apply block absolute w-[351px] inset-y-0 z-[1];
        background-image: image-set(
            url('../../../../resources/images/index-landing/1920/reviews-side-bg.svg')
                1x
        );
    }
}

.reviews__bg-right {
    @apply hidden pointer-events-none;
    @screen lg {
        @apply block absolute w-[351px] z-[1] rotate-180 right-0 inset-y-0;
        background-image: image-set(
            url('../../../../resources/images/index-landing/1920/reviews-side-bg.svg')
                1x
        );
    }
}

.reviews__title {
    @apply font-sans text-[32px] font-bold leading-[38px] tracking-[0px] text-center text-white pt-[40px] px-[20px];
    @screen lg {
        @apply text-[54px] leading-[66px] w-[750px] mx-auto my-0 pt-[80px] px-[unset];
    }
}

.reviews__img-container {
    @apply relative flex justify-center pt-[40px];
    @screen lg {
        @apply pt-[60px];
    }
}

.reviews__img {
    @screen lg {
        @apply w-[281px] h-[115px];
    }
}

.reviews__count {
    @apply absolute top-[60px] align-middle font-[700] text-[32px] leading-[18px] text-[#FFFFFF];
    @screen lg {
        @apply top-[80px];
    }
}

.reviews__carousel {
    @apply mt-[40px] relative;
    @screen lg {
        @apply mt-[60px];
        &:hover {
            .reviews__carousel-navigation {
                @apply flex;
            }
        }
    }
}

.reviews__carousel-navigation {
    @apply hidden;
    @screen lg {
        @apply absolute justify-between -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 pointer-events-none;
        width: calc((100%) - 680px);
    }
    @screen xxl {
        @apply w-[1240px];
    }
}

.reviews__carousel-navigation-btn {
    @apply cursor-pointer z-[1];
    pointer-events: all;
}

.reviews__carousel-navigation_right {
    @apply rotate-180;
}

.reviews__slide {
    @apply w-[315px] flex flex-col mx-[6px] my-0 p-[20px] rounded-[10px] cursor-pointer min-h-full;
    background: rgba(111, 100, 121, 0.3);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    @screen lg {
        @apply w-[380px] mx-[10px] my-0;
    }
}

.reviews__slide-header {
    @apply flex flex-row justify-between;
}

.reviews__slide-author {
    @apply font-sans text-[18px] font-bold leading-[21px] tracking-[0px] text-left text-white;
    @screen lg {
        @apply text-[22px] leading-[26px];
    }
}

.reviews__slide-text {
    @apply font-sans text-[16px] font-normal leading-[19px] tracking-[0px] text-left text-white mt-[14px];
    @screen lg {
        @apply text-[20px] leading-[26px] mt-[10px];
    }
}

.reviews__button-container {
    @apply flex justify-center mt-[32px] pb-[40px];
    @screen lg {
        @apply mt-[60px] pb-[80px];
    }
}

.reviews__button {
    @apply font-sans w-[335px] h-[68px] text-[20px] font-bold leading-[24px] tracking-[0px] text-center text-white rounded-[16px];
    background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    box-shadow: 0 0 4px 0 #00000018;
    transition: background 1s ease-out;

    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }
}
</style>

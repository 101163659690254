<template>
    <svg width="148" height="28" viewBox="0 0 148 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5205 20.6269C14.2009 20.432 13.7991 20.432 13.4795 20.6269L7.81899 24.0777C7.0602 24.5403 6.12009 23.8573 6.32556 22.9927L7.85829 16.5429C7.94485 16.1786 7.82071 15.7966 7.53659 15.5528L2.50549 11.2357C1.83106 10.657 2.19015 9.55183 3.07593 9.48006L9.68369 8.94468C10.0568 8.91445 10.3819 8.67831 10.5259 8.33276L13.077 2.21385C13.419 1.3936 14.581 1.3936 14.923 2.21385L17.4741 8.33276C17.6181 8.67831 17.9432 8.91445 18.3163 8.94468L24.9241 9.48006C25.8099 9.55183 26.1689 10.657 25.4945 11.2357L20.4634 15.5528C20.1793 15.7966 20.0551 16.1786 20.1417 16.5429L21.6744 22.9927C21.8799 23.8573 20.9398 24.5403 20.181 24.0777L14.5205 20.6269Z" fill="url(#paint0_linear_71_335)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M74.5205 20.6269C74.2009 20.432 73.7991 20.432 73.4795 20.6269L67.819 24.0777C67.0602 24.5403 66.1201 23.8573 66.3256 22.9927L67.8583 16.5429C67.9449 16.1786 67.8207 15.7966 67.5366 15.5528L62.5055 11.2357C61.8311 10.657 62.1901 9.55183 63.0759 9.48006L69.6837 8.94468C70.0568 8.91445 70.3819 8.67831 70.5259 8.33276L73.077 2.21385C73.419 1.3936 74.581 1.3936 74.923 2.21385L77.4741 8.33276C77.6181 8.67831 77.9432 8.91445 78.3163 8.94468L84.9241 9.48006C85.8099 9.55183 86.1689 10.657 85.4945 11.2357L80.4634 15.5528C80.1793 15.7966 80.0551 16.1786 80.1417 16.5429L81.6744 22.9927C81.8799 23.8573 80.9398 24.5403 80.181 24.0777L74.5205 20.6269Z" fill="url(#paint1_linear_71_335)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M44.5205 20.6269C44.2009 20.432 43.7991 20.432 43.4795 20.6269L37.819 24.0777C37.0602 24.5403 36.1201 23.8573 36.3256 22.9927L37.8583 16.5429C37.9449 16.1786 37.8207 15.7966 37.5366 15.5528L32.5055 11.2357C31.8311 10.657 32.1901 9.55183 33.0759 9.48006L39.6837 8.94468C40.0568 8.91445 40.3819 8.67831 40.5259 8.33276L43.077 2.21385C43.419 1.3936 44.581 1.3936 44.923 2.21385L47.4741 8.33276C47.6181 8.67831 47.9432 8.91445 48.3163 8.94468L54.9241 9.48006C55.8099 9.55183 56.1689 10.657 55.4945 11.2357L50.4634 15.5528C50.1793 15.7966 50.0551 16.1786 50.1417 16.5429L51.6744 22.9927C51.8799 23.8573 50.9398 24.5403 50.181 24.0777L44.5205 20.6269Z" fill="url(#paint2_linear_71_335)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M104.521 20.6269C104.201 20.432 103.799 20.432 103.479 20.6269L97.819 24.0777C97.0602 24.5403 96.1201 23.8573 96.3256 22.9927L97.8583 16.5429C97.9449 16.1786 97.8207 15.7966 97.5366 15.5528L92.5055 11.2357C91.8311 10.657 92.1901 9.55183 93.0759 9.48006L99.6837 8.94468C100.057 8.91445 100.382 8.67831 100.526 8.33276L103.077 2.21385C103.419 1.3936 104.581 1.3936 104.923 2.21385L107.474 8.33276C107.618 8.67831 107.943 8.91445 108.316 8.94468L114.924 9.48006C115.81 9.55183 116.169 10.657 115.495 11.2357L110.463 15.5528C110.179 15.7966 110.055 16.1786 110.142 16.5429L111.674 22.9927C111.88 23.8573 110.94 24.5403 110.181 24.0777L104.521 20.6269Z" fill="url(#paint3_linear_71_335)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M134.521 20.6269C134.201 20.432 133.799 20.432 133.479 20.6269L127.819 24.0777C127.06 24.5403 126.12 23.8573 126.326 22.9927L127.858 16.5429C127.945 16.1786 127.821 15.7966 127.537 15.5528L122.505 11.2357C121.831 10.657 122.19 9.55183 123.076 9.48006L129.684 8.94468C130.057 8.91445 130.382 8.67831 130.526 8.33276L133.077 2.21385C133.419 1.3936 134.581 1.3936 134.923 2.21385L137.474 8.33276C137.618 8.67831 137.943 8.91445 138.316 8.94468L144.924 9.48006C145.81 9.55183 146.169 10.657 145.495 11.2357L140.463 15.5528C140.179 15.7966 140.055 16.1786 140.142 16.5429L141.674 22.9927C141.88 23.8573 140.94 24.5403 140.181 24.0777L134.521 20.6269Z" fill="url(#paint4_linear_71_335)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M137.709 22.5647L137.709 19.3285V16.8226V13.2501V10.6984V8.89551L144.924 9.48012C145.81 9.55189 146.169 10.657 145.495 11.2358L140.463 15.5528C140.179 15.7966 140.055 16.1787 140.142 16.5429L141.674 22.9896C141.879 23.8547 140.938 24.5377 140.18 24.0742L137.709 22.5647Z" fill="#8A5C00"/>
        <defs>
            <linearGradient id="paint0_linear_71_335" x1="0" y1="0" x2="0" y2="28" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDA00A"/>
                <stop offset="1" stop-color="#EEC169"/>
            </linearGradient>
            <linearGradient id="paint1_linear_71_335" x1="60" y1="0" x2="60" y2="28" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDA00A"/>
                <stop offset="1" stop-color="#EEC169"/>
            </linearGradient>
            <linearGradient id="paint2_linear_71_335" x1="30" y1="0" x2="30" y2="28" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDA00A"/>
                <stop offset="1" stop-color="#EEC169"/>
            </linearGradient>
            <linearGradient id="paint3_linear_71_335" x1="90" y1="0" x2="90" y2="28" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDA00A"/>
                <stop offset="1" stop-color="#EEC169"/>
            </linearGradient>
            <linearGradient id="paint4_linear_71_335" x1="120" y1="0" x2="120" y2="28" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDA00A"/>
                <stop offset="1" stop-color="#EEC169"/>
            </linearGradient>
        </defs>
    </svg>
</template>
